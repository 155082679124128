// standard packages
import React, { useState, useEffect, useRef } from 'react'

// import styles
import styles from './country.module.scss'

// import 3rd party packages
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

// import components
import CountryOverview from '../../country/CountryOverview/CountryOverview'
import AdoptionOverview from '../../country/AdoptionOverview/AdoptionOverview'
import HivOverview from '../../country/HivOverview/HivOverview'
import PolicyAreaComparisons from '../../country/PolicyAreaComparisons/PolicyAreaComparisons'
import AreaScorecard from '../../country/AreaScorecard/AreaScorecard'
import {
  labelBinScale,
  colorBinScale,
} from '../../common/MapboxMap/plugins/data'
import { CountryHeader, StickyHeader } from '../../common'
import { arrSum, areaScorecardDefs, getDisplayYear } from '../../misc/Util'

const Country = ({
  // set page function allows current page to be set so nav emphasis is applied
  setPage,
  // 2-character ISO code of country
  iso2,
  // name of country
  place_name,

  // overall adoption levels
  overallAdoption,
  overall,
  policyAreaAdoption,
  policyAreaScores,
  epiData,
  indScores,
  subScores,
  globalAvg,
  regionalAvg,
  region,
  refs,
}) => {
  // STATE // ---------------------------------------------------------------//
  // track the height of the header on this page
  const [headerHeight, setHeaderHeight] = useState(null)
  const [simpleHeaderRef, setSimpleHeaderRef] = useState({ current: null })
  const [displayMiniHeader, setDisplayMiniHeader] = useState(false)

  // define current year of data to show for all charts except the header
  // which will show 2019 (most recent)
  const [year, setYear] = useState('recent')
  const [pct, setPct] = useState(0)

  // true if main component has initially mounted, false otherwise; required
  // for proper rehydration of React in live site
  const [hasMounted, setHasMounted] = useState(false)

  // state for whether popup is displayed or not
  const [popupActive, setPopupActive] = useState(false)
  const [yearsPopupContent, setYearsPopupContent] = useState({})
  const [popupPosition, setPopupPosition] = useState({ top: '50px' })

  // UTILITY FUNCTIONS // ---------------------------------------------------//
  /**
   * Set popup content for <IndicatorScore /> component
   * @method updatePopup
   * @param  {[type]}    popupContent [description]
   * @return {[type]}                 [description]
   */
  function updatePopup(info) {
    if (!popupActive) {
      setYearsPopupContent(info.content)
      const clicked = info.e.target.getBoundingClientRect()
      setPopupPosition({ top: clicked.top })
      setPopupActive(true)
    }
  }

  /**
   * Close the aforementioned popup
   * @method closePopup
   * @return {[type]}   [description]
   */
  function closePopup() {
    if (popupActive) {
      setPopupActive(false)
    }
  }

  /**
   * Given the number of adopted indicators in each bin for a given year for
   * all policy areas, return the total number of indicators.
   * @method getNumApplicableIndicators
   * @param  {[type]}                   policyAreaAdoptionForYear [description]
   * @return {[type]}                                             [description]
   */
  const getNumApplicableIndicators = policyAreaAdoptionForYear => {
    delete policyAreaAdoptionForYear.year
    const obj = { ...policyAreaAdoptionForYear, year: 0 }
    return arrSum(Object.values(obj))
  }

  // CONSTANTS // -----------------------------------------------------------//
  // maintain reference to header for sticky height calculations
  let headerRef = useRef(null)

  const indicatorScoresForYear = Object.values(indScores[year])
  const subindicatorScoresForYear = Object.values(subScores[year])
  const policyAreaScoresForYear = policyAreaScores[year]

  // get text to display wherever the current year is shown; special text for
  // `recent` option
  const displayYear = getDisplayYear(year)

  // define wrapper reference to support detecting when user clicks outside of
  // the popup, triggering an auto close (see effect hooks below)
  let wrapperRef = useRef(null)

  // overall adoption score for current time period
  // TODO apply to any time period
  let overallScore, overallYear
  if (overall[year] !== undefined && overall[year] !== null) {
    overallScore = overall[year].value
    overallYear = overall[year].date_time
  } else {
    overallScore = null
    overallYear = null
  }

  // get overall scores to display in bullet chart
  // reformat overall scores so they match format of policy area scores
  const overallFormatted = {}
  for (const [year, datum] of Object.entries(overall)) {
    overallFormatted[year] = { Overall: { value: datum.value } }
  }
  // add location info
  const overallScores = {
    ...overallFormatted,
    // iso2: d.iso2,
    // name: d.place_name,
  }

  const overallAdoptionFormatted = {}
  for (const [year, datum] of Object.entries(overallAdoption)) {
    overallAdoptionFormatted[year] = { Overall: {} }
    for (const [level, value] of Object.entries(datum)) {
      overallAdoptionFormatted[year].Overall[level] = value
    }
  }

  const curYearPolicyAreaAdoption = overallAdoption[year]

  const numApplicableIndicators = getNumApplicableIndicators(
    overallAdoption[year]
  )
  // Effect hooks
  useEffect(() => {
    setPage('country')
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (simpleHeaderRef.current !== null) {
      setHeaderHeight(simpleHeaderRef.current.getBoundingClientRect().height)
      window.addEventListener('resize', () => {
        if (headerRef.current !== null) {
          setHeaderHeight(
            simpleHeaderRef.current.getBoundingClientRect().height
          )
        }
      })
      window.addEventListener('scroll', () => {
        if (headerRef.current !== null) {
          setHeaderHeight(
            simpleHeaderRef.current.getBoundingClientRect().height
          )
        }
        if (headerRef.current !== null) {
          const bcr = headerRef.current.getBoundingClientRect()
          const displayThresh = bcr.top + bcr.height
          setDisplayMiniHeader(window.scrollY > displayThresh)
        }
      })
    }
  }, [simpleHeaderRef])

  // on click anywhere but in popup, and popup is shown, close popup; otherwise
  // do nothing
  useEffect(() => {
    if (popupActive)
      document.getElementById('___gatsby').onclick = e => {
        if (wrapperRef === null || wrapperRef.current === null) return
        const wrapper = wrapperRef.current
        if (wrapper && wrapper.contains(e.target)) {
          return
        } else {
          setPopupActive(false)
        }
      }
  }, [popupActive])

  // JSX
  if (!hasMounted) return null
  else
    return (
      <>
        <div className={styles.container}>
          <article className={styles.main}>
            <CountryHeader
              {...{ iso2, name: place_name, year, setYear, comparePage: false }}
            />
            <StickyHeader
              {...{
                show: displayMiniHeader,
                name: place_name,
                year,
                setYear,
                setSimpleHeaderRef,
                img: (
                  <img
                    className={styles.flag}
                    src={`https://flags.talusanalytics.com/shiny_100px/${iso2.toLowerCase()}.png`}
                  ></img>
                ),
              }}
            />
            <header ref={headerRef} className={styles.header}>
              <div className={styles.gridBox}>
                <CountryOverview
                  name={place_name}
                  iso2={iso2}
                  overallScore={overallScore}
                  overallYear={overallYear}
                  comparePage={false}
                />
              </div>
              <div className={styles.gridBox}>
                <AdoptionOverview
                  {...{
                    numApplicableIndicators,
                    curYearPolicyAreaAdoption,
                    overallYear,
                    comparePage: false,
                  }}
                />
              </div>
              <div className={classNames(styles.gridBox, styles.lastGridBox)}>
                <HivOverview year={year} epiData={epiData} />
              </div>
            </header>
            {
              <PolicyAreaComparisons
                displayYear={displayYear}
                globalAvg={globalAvg}
                isGlobal={false}
                name={place_name}
                overallAdoption={overallAdoptionFormatted}
                overallScores={overallScores}
                policyAreaAdoption={policyAreaAdoption}
                policyAreaScores={policyAreaScores}
                region={region}
                regionalAvg={regionalAvg}
                setYear={setYear}
                singleIso2={iso2}
                year={year}
              />
            }
            <h1 className={styles.scorecardHeading}>
              <div>Policy adoption by question</div>
              {/* <div className={styles.popupHint}>
                <div className={styles.questionIcon}>
                  <i className="fas fa-question-circle"></i>
                </div>
                <div>click icon to view details</div>
              </div> */}
            </h1>
            <div className={styles.scorecardContainer}>
              {areaScorecardDefs.map(({ area, abbrev }, idx) => (
                <div className={styles.scorecard} key={area}>
                  <AreaScorecard
                    {...{
                      key: area,
                      displayYear,
                      idx: areaScorecardDefs.length * 2 - (idx * 2 + 1) + 1,
                      year,
                      refs,
                      area,
                      abbrev,
                      updatePopup,
                      headerHeight,
                      closePopup,
                      backgroundColor: colorBinScale(
                        policyAreaScoresForYear[area].value
                      ),
                      overallScore:
                        policyAreaScoresForYear[area].value !== undefined &&
                        policyAreaScoresForYear[area].value !== null
                          ? labelBinScale(
                              policyAreaScoresForYear[area].value
                            ).toLowerCase()
                          : null,
                      data: indicatorScoresForYear.filter(d =>
                        d.code.startsWith(abbrev)
                      ),
                      subindicators: subindicatorScoresForYear.filter(d =>
                        d.code.startsWith(abbrev)
                      ),
                      yearsAllIndicators: subScores.years,
                    }}
                  />
                </div>
              ))}
            </div>
          </article>
          <ReactTooltip
            className={styles.tipContainer}
            type="light"
            id="statusTip"
            place="left"
            effect="solid"
          />
          <ReactTooltip
            type="light"
            id="simpleTip"
            place="top"
            effect="solid"
          />
        </div>
        {/* <div className={styles.footer}>
          <Footer />
        </div> */}
      </>
    )
}

export default Country
