/**
 * Features data for specific indicator, including
 * name, definition, and level of adoption for
 * specific country
 */

// standard packages
import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { FloatMenu } from '../../common'

// styles and assets
import styles from './indicatorscore.module.scss'
import '../../../assets/fonts/fontawesome/css/all.css'
import YearsPopup from '../YearsPopup/YearsPopup'
import calIconBlue from '../../../assets/icons/calIconBlue.png'
import calIconGray from '../../../assets/icons/calIconGray.png'

// import 3rd party packages
import classNames from 'classnames'

//import colors
import {
  goldenrod,
  darkTeal,
  softBlue,
  grayishCyan,
  softYellow2,
  lightGray3,
} from '../../../assets/styles/vars.scss'

// FUNCTION COMPONENT // ----------------------------------------------------//
/**
 * Indicator data row
 * @method IndicatorScore
 */
const IndicatorScore = ({
  // parameters
  subindicators,
  idx,
  closePopup,
  refs,

  // additional properties, if any
  ...props
}) => {
  // STATE // ---------------------------------------------------------------//
  //for keeping track of subindicator drawer visibility
  const [show, setShow] = useState(false)
  const [subsVisible, setSubsVisible] = useState(true)
  //for keeping track of description drawer visibility (on mobile)
  const [descripVisible, setDescripVisible] = useState(false)

  // UTILITY FUNCTIONS // ---------------------------------------------------//
  //hide and show subindicators
  function toggleSubs() {
    if (!subsVisible) {
      setSubsVisible(true)
    } else {
      setSubsVisible(false)
    }
  }

  //hide and show description
  function toggleDescrip() {
    if (!descripVisible) {
      setDescripVisible(true)
    } else {
      setDescripVisible(false)
    }
  }

  //display cells or no data cells
  let cells
  if (props.level === null || props.level === undefined) {
    cells = <NoDataCells />
  } else {
    cells = <DataCells level={props.level} />
  }

  //display text if more than one parts/subindicators
  const parts = subindicators.length
  let partsText, caret
  if (parts > 1) {
    partsText = ' (' + parts + '‑part)'
    caret = (
      <i className={subsVisible ? 'fas fa-angle-up' : 'fas fa-angle-down'}></i>
    )
  } else {
    partsText = ''
    caret = ''
  }

  //parse policy area for popup launch
  let policyArea = props.area
  let replacingNeeded = policyArea.toString().search('/')
  if (replacingNeeded != -1) {
    policyArea = policyArea.replace('/', ' and')
  }

  //get string for policy number
  let str = props.code
  let numIndex = str.search(/\d/)
  if (numIndex != -1) {
    let policyNumber = str.slice(numIndex)
    var policy = 'Policy ' + policyNumber
  } else {
    policy = 'Policy 0'
  }

  //translate into roman numerals
  function intoRomanNums(integer) {
    switch (integer) {
      case 1:
        return 'i'
      case 2:
        return 'ii'
      case 3:
        return 'iii'
      case 4:
        return 'iv'
      case 5:
        return 'v'
      default:
        return 'i'
    }
  }

  // EFFECT HOOKS // --------------------------------------------------------//

  // JSX // -----------------------------------------------------------------//
  const [customClose, setCustomClose] = useState(false)
  return (
    <>
      <div className={styles.container}>
        <div className={styles.desktopCode}>
          <div className={classNames(styles.code, 'notranslate')}>
            {props.code}
          </div>
        </div>
        <div className={styles.title}>
          <div>
            {props.title}
            <span
              className={styles.parts}
              onClick={parts > 1 ? () => toggleSubs() : null}
            >
              {partsText}
            </span>
          </div>
        </div>
        <div className={styles.description}>
          <div>
            <FloatMenu
              control={() => (
                <div className={styles.questionIcon}>
                  <i className="fas fa-question-circle"></i>
                </div>
              )}
              customClose={customClose}
              setCustomClose={setCustomClose}
            >
              <YearsPopup
                refs={refs}
                zIndex={idx - 1}
                content={{
                  code: props.code,
                  policyArea: policyArea,
                  title: props.title,
                  description: props.descrip,
                  years: props.years,
                  policyNum: policy,
                }}
                closePopup={() => {
                  setCustomClose(true)
                }}
              />
            </FloatMenu>
          </div>
          <div>{props.descrip}</div>
        </div>
        {cells}
        <div
          className={styles.caret}
          onClick={parts > 1 ? () => toggleSubs() : null}
        >
          {caret}
        </div>
        <div className={styles.mobileCaret} onClick={() => toggleDescrip()}>
          <i
            className={descripVisible ? 'fas fa-angle-up' : 'fas fa-angle-down'}
          ></i>
        </div>
      </div>
      {/* Display subindicators if available and toggled */}
      {subsVisible && parts > 1 && (
        <div className={styles.subContainer}>
          {subindicators.map((s, index) => (
            <div className={styles.subindicator}>
              <div></div>
              <div className={classNames(styles.subCode, 'notranslate')}>
                {intoRomanNums(index + 1)}
              </div>
              <div className={styles.subDescription}>{s.descrip}</div>
              {s.level !== null && s.level !== undefined ? (
                <ShortDataCells level={s.level} />
              ) : (
                NoDataCells({ short: true })
              )}
              <div></div>
            </div>
          ))}
        </div>
      )}
      {/* Display description if available and toggled (on mobile) */}
      {descripVisible && (
        <div className={styles.mobileDescription}>{props.descrip}</div>
      )}
    </>
  )
}

function NoDataCells({ short = false }) {
  return (
    <div className={styles.genericCellContainer}>
      <div className={short ? styles.shortCellContainer : styles.cellContainer}>
        <div className={classNames(styles.noDataCell, styles.first)}></div>
        <div className={classNames(styles.noDataCell)}></div>
        <div className={classNames(styles.noDataCell, styles.last)}></div>
      </div>
      <div className={styles.explainer}>
        <i style={short ? { fontSize: '1rem' } : {}}>data not available</i>
      </div>
    </div>
  )
}

function DataCells(props) {
  return (
    <div className={styles.genericCellContainer}>
      <div className={styles.cellContainer}>
        <div
          className={classNames(styles.dataCell, styles.first, {
            [styles.selected]: props.level === 'not adopted',
          })}
        ></div>
        <div
          className={classNames(styles.dataCell, {
            [styles.selected]: props.level === 'partial',
          })}
        ></div>
        <div
          className={classNames(styles.dataCell, styles.last, {
            [styles.selected]: props.level === 'adopted',
          })}
        ></div>
      </div>
    </div>
  )
}
function ShortDataCells(props) {
  return (
    <div className={styles.genericCellContainer}>
      <div className={styles.shortCellContainer}>
        <div
          className={classNames(styles.dataCell, styles.first, {
            [styles.selected]: props.level === 'not adopted',
          })}
        ></div>
        <div
          className={classNames(styles.dataCell, {
            [styles.selected]: props.level === 'partial',
          })}
        ></div>
        <div
          className={classNames(styles.dataCell, styles.last, {
            [styles.selected]: props.level === 'adopted',
          })}
        ></div>
      </div>
    </div>
  )
}
export default IndicatorScore
