/**
 * Scorecard featuring indicator data for specific
 * policy area (eg. CT, TP...). Holds many IndicatorScore
 * components - one for each indicator
 */

// standard packages
import React from 'react'

// styles and assets
import styles from './areascorecard.module.scss'

// local utility functions
import { colorBinScale } from '../../common/MapboxMap/plugins/data'

// import components
import IndicatorScore from '../IndicatorScore/IndicatorScore'
import AdoptionStatusInfoTip from '../AdoptionStatusInfoTip/AdoptionStatusInfoTip'

//import colors
import {
  goldenrod,
  darkTeal,
  softBlue,
  grayishCyan,
  softYellow2,
  noDataGray,
} from '../../../assets/styles/vars.scss'

// import 3rd party packages
import classNames from 'classnames'
import { Link } from 'gatsby'

// FUNCTION COMPONENT // ----------------------------------------------------//
/**
 * Scorecard for specific policy area
 * @method AreaScorecard
 */
const AreaScorecard = ({
  // data
  data,
  subindicators,
  backgroundColor,
  year,
  headerHeight,
  idx,
  closePopup,
  overallScore,
  refs,
  yearsAllIndicators,
  // additional properties, if any
  ...props
}) => {
  // CONSTANTS // -----------------------------------------------------------//
  const indScores = data.map(i => {
    const curRefs = []
    const curSubindicators = subindicators.filter(d => {
      const isMatch = d.parentCode === i.code
      const hasRefs =
        refs[d.year] !== undefined && refs[d.year][d.code] !== undefined
      if (isMatch && hasRefs) {
        const refList = []
        refs[d.year][d.code].forEach(dd => {
          refList.push(dd)
        })
        curRefs.push({
          code: d.code,
          refList: refList,
        })
      }
      return isMatch
    })

    // filter out year data for all applicable subindicators
    const curYears = []
    const years = Object.keys(yearsAllIndicators)
    years.forEach(d => {
      if (
        d === i.code ||
        d.startsWith(i.code + 'i') ||
        d.startsWith(i.code + 'v')
      ) {
        curYears.push({
          subInd: d,
          years: yearsAllIndicators[d],
        })
      }
    })
    return (
      <IndicatorScore
        closePopup={closePopup}
        refs={curRefs}
        key={i.code + i.title}
        idx={idx}
        code={i.code}
        title={i.title}
        area={props.area}
        descrip={i.descrip}
        level={i.level}
        parts={curSubindicators.length}
        subindicators={curSubindicators}
        updatePopup={props.updatePopup}
        years={curYears}
      />
    )
  })

  // UTILITY FUNCTIONS // ---------------------------------------------------//

  // EFFECT HOOKS // --------------------------------------------------------//

  // JSX // -----------------------------------------------------------------//
  return (
    <div className={styles.container}>
      <div
        style={{ top: headerHeight, zIndex: idx }}
        className={classNames(
          styles.header,
          overallScore && styles[overallScore.replace(' ', '')],
          {
            [styles.hasDataButNoScore]: overallScore === null,
          }
        )}
      >
        <div className={styles.title}>
          <div className={styles.policyAreaTitle}>
            <div className={styles.areaHead}>
              {props.area}
              <Link className={styles.contact} to={'/contact'}>
                <div>
                  <i className="fas fa-envelope"></i>
                  Contact Us
                </div>
              </Link>
            </div>
          </div>
          <div className={styles.scoreGroupAndMessage}>
            <div className={styles.scoreGroup}>
              <div className={classNames(styles.abbrev, 'notranslate')}>
                {props.abbrev}
              </div>
              <div className={styles.score}>
                {overallScore !== null
                  ? overallScore.toString().toUpperCase()
                  : '—'}
              </div>
            </div>
            {overallScore === null && (
              <i className={styles.hasDataButNoScore}>
                Insufficient data to calculate score
              </i>
            )}
          </div>
        </div>
        <div className={styles.scorecardHeader}>
          <div>Category / topic</div>
          <div className={styles.hideOnMobile}>
            <span
              className={styles.questionIcon}
              data-tip={'Click these icons to view details'}
              data-for="simpleTip"
            >
              <i className="fas fa-question-circle"></i>
            </span>
            Question
          </div>
          <div>
            Adoption level
            <AdoptionStatusInfoTip />
          </div>
        </div>
        <div className={styles.labelRow}>
          <div></div>
          <div className={styles.tableLabels}>
            <div>
              Not <br />
              adopted
            </div>
            <div>
              Partially <br />
              adopted
            </div>
            <div>Adopted</div>
          </div>
        </div>
      </div>
      {indScores}
    </div>
  )
}

export default AreaScorecard
