// standard packages
import React, { useState, useEffect } from 'react'

// import styles
import styles from './country.module.scss'

// import 3rd party packages
import classNames from 'classnames'
import { Link } from 'gatsby'

// import components
import Footer from '../../Layout/Footer/Footer'
import CountryOverview from '../../country/CountryOverview/CountryOverview'
import { CountryHeader } from '../../common'

const CountryNoData = ({
  // 2-character ISO code of country
  iso2,
  // name of country
  place_name,
  region,
  setPage,
}) => {
  // STATE // ---------------------------------------------------------------//
  const [hasMounted, setHasMounted] = useState(false)

  // Effect hooks
  useEffect(() => {
    setPage('country')
    setHasMounted(true)
  }, [])

  // JSX
  if (!hasMounted) return null
  else
    return (
      <div className={styles.container}>
        <div className={styles.countryNoData}>
          <article className={styles.main}>
            <CountryHeader {...{ iso2, name: place_name, noDataYet: true }} />
            <header className={styles.header}>
              <div className={classNames(styles.gridBox, styles.lastGridBox)}>
                <div className={styles.noData}>
                  <div>Data are not yet available for this location.</div>
                  <Link to={'/contact'}>
                    <button className={styles.contactButton}>
                      <i className="fas fa-envelope"></i> send us info
                    </button>
                  </Link>
                </div>
              </div>
            </header>
          </article>
        </div>
        {/* <div className={styles.footer}>
          <Footer />
        </div> */}
      </div>
    )
}

export default CountryNoData
