/**
 * Popup for showing years of available data
 * for each indicator on country page
 */

// standard packages
import React from 'react'

// 3rd party packages
import classNames from 'classnames'
import { Link } from 'gatsby'

// assets and styles
import styles from './yearspopup.module.scss'
import close from '../../../assets/styles/popups/close.svg'

import { darkTeal, noDataGray } from '../../../assets/styles/vars.scss'

// FUNCTION COMPONENT // ----------------------------------------------------//
const YearsPopup = React.forwardRef((props, ref) => {
  // define semicolon-delimited listed of sources, including PDF hyperlinks
  // if available
  const allSources = props.refs.length > 0 ? [] : null
  const attachments = []
  props.refs.forEach((subindicator, i) => {
    const subindSources = []
    //for each subindicator, iterate through list of refs
    subindicator.refList.forEach((ref, idx) => {
      if (ref !== 'null') {
        //see if item is the last in the list
        if (!ref.includes('attachment_')) {
          if (ref.startsWith('https')) {
            if (!attachments.includes(ref)) {
              attachments.push(ref)
            }
          } else {
            subindSources.push(
              <span key={ref}>{ref.replace('citation_', '')}</span>
            )
          }
        } else {
          const refArr = ref.split('_')
          const refUrl = refArr[refArr.length - 1]
          if (!attachments.includes(refUrl)) {
            attachments.push(refUrl)
          }
        }
      }
    })
    if (subindSources.length !== 0) {
      allSources.push({
        code: subindicator.code,
        refList: subindSources,
      })
    }
  })

  //do some prep for displaying years data is available
  const yearData = props.content.years
  yearData.forEach(subindicator => {
    // sort years properly
    subindicator.years = subindicator.years.sort(function (a, b) {
      if (a.year < b.year) return -1
      else return 1
    })
    // get roman numeral if applicable
    let code = subindicator.subInd.split('-')[0].trim()
    subindicator.numeral = code.replace(props.content.code, '')
  })

  const downloadLinks = () => {
    if (window !== undefined) {
      let count = 0
      const downloadURL = (url, callback = () => '') => {
        // create hidden iframe that triggers file download
        const hiddenIFrameID = 'hiddenDownloader' + count++
        const iframe = document.createElement('iframe')
        iframe.id = hiddenIFrameID
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
        iframe.src = url
        // clean up iframes after 2 sec
        setTimeout(() => {
          iframe.remove()
        }, 2000)
      }
      attachments.forEach(url => {
        downloadURL(url)
      })
    }
  }
  return (
    <div style={{ zIndex: props.zIndex }} className={styles.popupView}>
      <div className={styles.container} ref={ref}>
        <div className={styles.header}>
          <div className={styles.code}>{props.content.code}</div>
          <div className={styles.title}>{props.content.title}</div>
          <div className={styles.policy}>{props.content.policyArea}</div>
          <div className={styles.policyNum}>{props.content.policyNum}</div>
          <div className={styles.description}>{props.content.description}</div>
          <div className={classNames(styles.source, styles.libraryLink)}>
            <a
              target="_blank"
              href={`/codebook#` + props.content.code}
              rel="noopener noreferrer"
            >
              <span>go to codebook</span>
            </a>
            <a target="_blank" href="/sources" rel="noopener noreferrer">
              <span>go to sources</span>
            </a>
            {attachments.length !== 0 && (
              <a className={styles.attachLink} onClick={() => downloadLinks()}>
                download policy documents
                <span className={styles.attachIcon}>
                  <i className={classNames('material-icons', 'notranslate')}>
                    attachment
                  </i>
                </span>
              </a>
            )}
          </div>
          <img
            className={styles.closeButton}
            src={close}
            onClick={() => props.closePopup()}
          ></img>
        </div>
        <div className={styles.content}>
          <div className={styles.label}>Data available for</div>
          <div className={styles.yearBoxes}>
            {yearData.map(subindicator => {
              //list of refs for subindicator
              let sourceList = []

              //if refs exist, select appropriate refList for current indicator and assign to sourceList
              if (allSources !== null) {
                //grab reflist for current subindicator from allsources
                const code = subindicator.subInd.split('-')[0].trim()
                allSources.forEach(subindicator => {
                  if (subindicator.code === code) {
                    sourceList = subindicator.refList
                  }
                })
                // define noun plurality to use as list of source introduction
                var sourceNoun =
                  sourceList.length !== 0
                    ? `${sourceList.length > 1 ? 'Sources' : 'Source'}`
                    : null
              }

              return (
                <div
                  key={subindicator.code + subindicator.numeral}
                  className={styles.subindicatorRow}
                >
                  <div className={styles.yearsRow}>
                    {subindicator.numeral != '' && (
                      <div className={styles.subCode}>
                        {subindicator.numeral}
                      </div>
                    )}
                    {subindicator.years.map(
                      y =>
                        y.year > 2016 && (
                          <div
                            key={subindicator.code + ' - ' + y.year}
                            className={classNames(
                              styles.yearDataCell,
                              y.data == 'yes' ? styles.active : null
                            )}
                          >
                            {y.year}
                          </div>
                        )
                    )}
                  </div>
                  <div className={classNames(styles.source, styles.sourceList)}>
                    {sourceList.length !== 0 ? (
                      <div
                        className={
                          subindicator.numeral === '' ? null : styles.indent
                        }
                      >
                        <i className={styles.sourcesText}>
                          {sourceNoun}: {sourceList}
                        </i>
                      </div>
                    ) : (
                      <div
                        className={
                          subindicator.numeral === '' ? null : styles.indent
                        }
                      >
                        <i>Source data not currently available</i>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
})

export default YearsPopup
