import React, { useState } from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Country from '../components/views/Country/Country'
import CountryNoData from '../components/views/Country/CountryNoData'

export default function CountryTemplate({
  pageContext: {
    noData,
    iso2,
    place_name,
    overall,
    overallAdoption,
    policyAreaAdoption,
    policyAreaScores,
    epiData,
    indScores,
    subScores,
    globalAvg,
    regionalAvg,
    region,
    refs,
  },
}) {
  const [page, setPage] = useState(null)
  return (
    <Layout page={page}>
      <SEO
        //TODO: change this to be the actual name of the country?
        title={place_name}
        description="Detailed overview of HIV policies for specific countries"
      />
      {noData && <CountryNoData {...{ setPage, place_name, iso2, region }} />}
      {!noData && (
        <Country
          {...{
            page,
            setPage,
            iso2,
            place_name,
            overall,
            overallAdoption,
            policyAreaAdoption,
            policyAreaScores,
            epiData,
            indScores,
            subScores,
            globalAvg,
            region,
            regionalAvg: regionalAvg[region],
            refs,
          }}
        />
      )}
    </Layout>
  )
}
