/**
 * Footer displaying logos
 */

// standard packages
import React from 'react'

// import styles
import styles from './footer.module.scss'

// import 3rd party packages
import classNames from 'classnames'

// assets
import logo from '../../../assets/images/logo.svg'
import talus from '../../../assets/images/talus.png'
import law from '../../../assets/images/law.png'
import unaids from '../../../assets/images/unaids.png'
import georgetown from '../../../assets/images/georgetown.png'

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.mainLogo}>
        <a href="/">
          <img
            className={styles.hplLogo}
            src={logo}
            alt={'HIV Policy Lab logo'}
          ></img>
        </a>
      </div>
      <div className={styles.logos}>
        <a href={'https://www.law.georgetown.edu/'} target="_blank">
          <img
            className={styles.law}
            src={law}
            alt={'Georgetown Law logo'}
          ></img>
        </a>
        <a href={'https://talusanalytics.com/'} target="_blank">
          <img
            className={styles.talus}
            src={talus}
            alt={'Talus Analytics logo'}
          ></img>
        </a>
        <a href={'https://www.unaids.org/en'} target="_blank">
          <img
            className={styles.unaids}
            src={unaids}
            alt={'Joint United Nations Programme on HIV and AIDS logo'}
          ></img>
        </a>
        <a href={'https://ghss.georgetown.edu/'} target="_blank">
          <img
            className={styles.georgetown}
            src={georgetown}
            alt={
              'Georgetown Center for Global Health Science and Security logo'
            }
          ></img>
        </a>
      </div>
      <div></div>
      <div className={styles.copyright}>
        <i>Produced by Georgetown University 2024</i>
      </div>
    </div>
  )
}

export default Footer
